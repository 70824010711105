@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: montserrat;
  src: url("./fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: montserrat;
  src: url("./fonts/Montserrat-SemiBold.ttf");
  font-weight: bold;
}

@layer base {
  html {
    font-family: montserrat;
    height: 100%;
    margin: 0;
    @apply bg-dark;
  }

  body {
    height: 100%;
    margin: 0;
    @apply text-white;
  }
}
